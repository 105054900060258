import React, { Component } from "react";
//import Fullscreen from 'react-fullscreen-crossbrowser';
//import { Carousel } from 'react-carousel-minimal';
/* import { withKnobs, boolean, number, text } from '@storybook/addon-knobs';
 */
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import Modal from 'react-modal';

import { format } from "date-fns";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class App extends Component {
  intervalID;

  constructor(props) {
    super(props);
    this.state = {
      isFullscreenEnabled: false,
      images: [],
      persons: [],
      Listpathvalue: [],
      Listpathnewvalue: [],
      sweeterArray2: [],
      datas: [],
      slides: null,
      sweeterArray3: [],
      imagelist: [],
      entries: [],
      count: 10,
      intervalId: 0,
      today: Date,
      todays: Date,
      baslangictarihi: "",
      //bastar:Date //Date().toLocaleString()
    };
    //this.handleClick = this.handleClick.bind(this);
    //this.clearCacheData = this.clearCacheData.bind(this);
    this.load = this.load.bind(this);





  }
  // console.log("yenilendim");

  /*
  
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
  <html>
  <head>
  <script language="JavaScript">
  function fullScreen(theURL) {
  window.open(theURL, '', 'fullscreen=yes, scrollbars=auto');
  }
  // End -->
  </script>
  </head>
  <body><h1 style="text-align: center;">
  Open In Full Screen
  </h1><div style="text-align: center;"><br>
  <a href="javascript:void(0);" onclick="fullScreen('http://google.com');">
  Open Full Screen Window
  </a>
  </div>
  </body>
  </html>
   
  More details Checkout below link 
  http://stackoverflow.com/questions/1125084/how-to-make-in-javascript-full-screen-windows-stretching-all-over-the-screen
  */





  load() {
/*     <Fullscreen/>
 */     moment.locale("de");
   // document.documentElement.requestFullscreen().then(() => console.log("calistim") );

    // document.documentElement.requestFullscreen()
    const tempDate = moment().format();

    var bugun = tempDate.replace("-", "").replace("-", "").substring(0, 8);
    console.log(bugun);


    this.setState({ bugun })

    const datas = axios.get(`https://mymedyal.madilink.net/Airjin/tumlistegetir`)
      .then(res => {
        const data = res.data;
        this.setState({ data })

        const bittar = this.state.data.map(image => image.bittar)

        this.setState({ bitistarihi: bittar })

        axios.get("https://mymedyal.madilink.net/Airjin/ayrintililistegetir/" + this.state.bugun + "/" + this.state.bitistarihi)
          .then(res => {

            const images = res.data;
            this.setState({ images })
            const sweeterArray = this.state.images.map(image => image.Listpath);
            this.setState({ sweeterArray })

            const sweeterArray2 = sweeterArray.toString().split('https://mymedyal.madilink.net/files/').filter(e => e)
            const sweeterArray3 = sweeterArray2.map(image => image.split(','));
            console.log(sweeterArray3)

            this.setState({ sweeterArray3 })

            // const entries = Object.entries(sweeterArray3);
            //this.setState({ entries })

            const baseChildren = this.state.sweeterArray3.map(images =>
              images.map((index) => {
                /*                 console.log(this.state.entries);
                 */
                return (
                  <div key={index}>
                    <img src={`https://mymedyal.madilink.net/files/${index}`} onError={(event) => event.target.style.display = 'none'} />

                  </div>
                )
              })
            )
            this.setState({ baseChildren })
            this.setState({
              slides: this.state.baseChildren,
            });

          })
      })


    // this.myFunction();

  }


  /*   launchIntoFullscreen(element) {
      if(element.requestFullscreen) {
        element.requestFullscreen();
      } else if(element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if(element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if(element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
   */





  componentDidMount() {



    //this.launchIntoFullscreen(document.documentElement); // the whole page
    //this.launchIntoFullscreen(document.getElementById("app")); // any individual element


    /* document.querySelector("app")
     if (!document.fullscreenElement) {
       document.documentElement.requestFullscreen().catch(err => {
         alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
       });
     } else {
       document.exitFullscreen();
     }
      */

    // document.documentElement.requestFullscreen(true);
    this.load()
    //this.toggleFullScreen();
  }

  myFunction() {

    /* var goFS = document.getElementById("goFS");
    this.state.datas.addEventListener("loadeddata", function() {
        document.body.requestFullscreen();
    }, false); */






    setInterval(function () {

      window.location.reload(false);
      window.location.href = "https://medyal.madilink.net";

    }, 30000);
  }

  /* shouldComponentUpdate(prevProps, prevState) {
    if (prevState.bugun !== this.state.bugun) {
       this.load()
    // console.log("geldim" + prevState)
      return false
    }
    
   // console.log("gelmedim" + prevState)
    return true;
  }
 */




  render() {

    const { images, persons, sweeterArray, sweeterArray2, slides } = this.state

    return <div>




      <Carousel
        style={{
          height: 100,
          with: 100,
        }}
        id="p1"
        xs={12} ld={12} md={12}
        autoPlay={true}
        //  itemSize={5} 
        // width={Window.innerWidth / 2}
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        dynamicHeight={false}
        swiping={true}
        showThumbs={false}
        infiniteLoop={true}
        interval={5000}
        stopOnHover={false}
        transitionTime={500}
        centerMode={false}
        animationHandler="fade"
        swipeable={false}>

        {slides}

      </Carousel>

    </div>
  }
}

App.defaultProps = {
  //baseChildren: <div>{[1, 2, 3, 4, 5].map(createCarouselItemImage)}</div>


}
export default App;
